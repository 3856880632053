import React, { Component } from 'react';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import Home from "./Pages/Home.js";
import Land from './Pages/Lands.js';
import Book from './Pages/Book.js';
import About from './Pages/About.js';
import Services from './Pages/Services.js';
import ContactUs from './Pages/ContactUs.js';
import Learning from './Pages/Learning.js';
import Socials from './Pages/Socials.js';
import LandsMobile from './Pages/landsMobile.js';

function App() {

	return (
		<div>
			<BrowserRouter>
				<Routes>

					<Route path='/' element={<Home />} />
					<Route path='/lands' element={<Land />} />
					<Route path='/book' element={<Book />} />
					<Route path='/about' element={<About />} />
					<Route path='/socials' element={<Socials />} />
					<Route path='/learning' element={<Learning />} />
					<Route path='/services' element={<Services />} />

				</Routes>
			</BrowserRouter>
			<div>


			</div>
		</div>




	)
}


export default App;
