import logo from '../assets/logo.png';
import element from '../assets/element10.png';
import whatsapp from '../assets/whatsapp.png';
import React, { useState } from 'react';
import navbar from '../assets/navigation-bar.png';
import wrong from '../assets/wrong.png';

/*
const handle = () => {
    mailtoLink({ to: 'info@teenchimpspire.com', subject: 'Nice mailto lib' });
    console.log("MailCode");
}*/

const handle2 = () => {
    window.open("#");
}

const handle3 = () => {
    window.open("#");
}

const handle4 = () => {
    window.open("#");
}


const Socials = () => {
    const [_navbarOpen, set_navbarOpen] = useState(0);

    async function closeNav() {
        set_navbarOpen(0);
        //this.setState({ _navbarOpen: 0 });
    }

    async function navbarOpen() {
        set_navbarOpen(1);
        //this.setState({ _navbarOpen: 0 });
    }

    return (
        <div className='allwrapSocial'>
            <div className='allwrapAbout2'>
                <div class="headers">

                    <div class="headers2">

                        <div class="logo" onClick={() => window.location.href = '/'}>
                            <img class="logoPic" src={logo} onClick={() => window.location.href = '/'} />
                        </div>

                        <div class="right">
                            <div class="navDiv" onClick={() => window.location.href = 'about'}>About</div>
                            <div class="navDiv" onClick={() => window.location.href = 'services'}>Services</div>
                            <div class="navDiv" onClick={() => window.location.href = 'services'}>Contact Us</div>
                            <div class="navDiv" onClick={() => window.location.href = 'learning'}>Learning</div>
                            <div class="navDiv" onClick={() => window.location.href = 'socials'}><span className='onDiv'>Community</span></div>
                        </div>

                    </div>

                </div>
                {_navbarOpen < 1 ?
                    (<div class="miniBarMain">
                        <img className='logoPic' src={logo} onClick={() => window.location.href = '/'} />

                        <div class="navbarMain"><img class="navbar" onClick={navbarOpen} src={navbar} /></div>
                    </div>) :
                    (<div class="miniBarMain">
                        <img className='logoPic' src={logo} onClick={() => window.location.href = '/'} />

                        <div class="navbarMain"><img class="navbar" onClick={closeNav} src={wrong} /></div>
                    </div>)}

                {_navbarOpen > 0 ?
                    (<div class="littleNav">
                        <div class="navDiv" onClick={() => window.location.href = 'about'}>About</div>
                        <div class="navDiv" onClick={() => window.location.href = 'services'}>Services</div>
                        <div class="navDiv" onClick={() => window.location.href = 'services'}>Contact Us</div>
                        <div class="navDiv" onClick={() => window.location.href = 'learning'}>Learning</div>
                        <div class="navDiv" onClick={() => window.location.href = 'socials'}><span className='onDiv'>Community</span></div>
                    </div>) : null}

                <div class="footer">

                    <div class="footerHeader">JOIN THE EMPIRE</div>
                    <div class="footerP">Join our discord community to stay up to date with project updates, rewards & giveaways.
                        For all inquiries please send us an email at info@creaters.com.

                    </div>

                    <div class="line"></div>

                    <div class="iconsSection1">
                        <button class="footerBox1" onClick={handle2}>

                            <div class="footerDetails">Send Your Message

                                <p>info@creaters.com</p>
                            </div>
                        </button>
                        <button class="footerBox2" onClick={handle2}>
                            <div class="footerDetails">INSTAGRAM
                                <p>Join the Community</p>
                            </div>
                        </button>
                    </div>

                    <div class="iconsSection2">
                        <button class="footerBox3" onClick={handle3}>
                            <div class="footerDetails">TWITTER
                                <p>Tweet Tweet</p>
                            </div>
                        </button>
                        <button class="footerBox4" onClick={handle4}>
                            <div class="footerDetails">DISCORD
                                <p>Join the Community</p>
                            </div>
                        </button>
                    </div>


                </div>
            </div>

            <div className='elementSocialDiv'><img className='elementSocial' src={element} /></div>

            <div className='whatsappDiv'><img className='whatsapp' src={whatsapp} /></div>

        </div>
    );
};

export default Socials;