import logo from '../assets/logo.png';
import section1Img from '../assets/IMG_2034.PNG';
import whatsapp from '../assets/whatsapp.png';
import React, { useState } from 'react';
import right from '../assets/right-arrow.png';
import left from '../assets/left-arrow.png';
import navbar from '../assets/navigation-bar.png';
import wrong from '../assets/wrong.png';

const About = () => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [_navbarOpen, set_navbarOpen] = useState(0);

    async function closeNav() {
        set_navbarOpen(0);
        //this.setState({ _navbarOpen: 0 });
    }

    async function navbarOpen() {
        set_navbarOpen(1);
        //this.setState({ _navbarOpen: 0 });
    }

    const detailsEggs = [
        {
            id: 'detailsEggSub',
            title: '3D Animation',
            description: 'Our institute offers comprehensive 3D animation courses designed to equip students with the skills and knowledge needed to excel in the animation industry. Our curriculum covers a wide range of topics, including character modeling, rigging, texturing, and animation principles. With state-of-the-art facilities and experienced instructors, students gain hands-on experience using industry-standard software and tools. Whether youre a beginner or looking to advance your skills, our courses provide a solid foundation and the creative environment needed to bring your animated visions to life. Join us to turn your passion for animation into a rewarding career.',
            imgSrc: 'https://eggs-75z.pages.dev/imgs/3danimation.PNG'
        },
        {
            id: 'detailsEggSub2',
            title: 'Game Development',
            description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing LoremIpsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.',
            imgSrc: 'https://eggs-75z.pages.dev/imgs/Game%20development.PNG'
        },
        {
            id: 'detailsEggSub3',
            title: 'Graphic Designer',
            description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing LoremIpsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.',
            imgSrc: 'https://eggs-75z.pages.dev/imgs/Graphicdesigner.PNG'
        },
        {
            id: 'detailsEggSub4',
            title: 'Sample',
            description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing LoremIpsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.',
            imgSrc: 'https://eggs-75z.pages.dev/imgs/IMG_2007.png'
        },
        {
            id: 'detailsEggSub5',
            title: 'Sample',
            description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing LoremIpsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.',
            imgSrc: 'https://eggs-75z.pages.dev/imgs/IMG_2008.png'
        },
        {
            id: 'detailsEggSub6',
            title: 'Metaverse',
            description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing LoremIpsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.',
            imgSrc: 'https://eggs-75z.pages.dev/imgs/Metaverse.PNG'
        },
        {
            id: 'detailsEggSub7',
            title: 'Mobile App Development',
            description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing LoremIpsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.',
            imgSrc: 'https://eggs-75z.pages.dev/imgs/Mobile%20app%20development.PNG'
        },
        {
            id: 'detailsEggSub8',
            title: 'Uiux',
            description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing LoremIpsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.',
            imgSrc: 'https://eggs-75z.pages.dev/imgs/Uiux.PNG'
        },
        {
            id: 'detailsEggSub9',
            title: 'Video Editing',
            description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing LoremIpsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.',
            imgSrc: 'https://eggs-75z.pages.dev/imgs/Videoediting.png'
        },
        {
            id: 'detailsEggSub10',
            title: 'Visual fx',
            description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing LoremIpsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.',
            imgSrc: 'https://eggs-75z.pages.dev/imgs/Visual%20fx.PNG'
        },
    ];

    const showNext = () => {
        setCurrentIndex((currentIndex + 1) % detailsEggs.length);
    };

    const showPrevious = () => {
        setCurrentIndex((currentIndex - 1 + detailsEggs.length) % detailsEggs.length);
    };

    const handleEggClick = (index) => {
        setCurrentIndex(index);
    };

    return (
        <div className='allwrapAbout'>
            <div className='allwrapAbout2'>
                <div className="headers">
                    <div className="headers2">
                        <div className="logo" onClick={() => window.location.href = '/'}>
                            <img className="logoPic" onClick={() => window.location.href = '/'} src={logo} alt="Logo" />
                        </div>
                        <div className="right">
                            <div className="navDiv" onClick={() => window.location.href = 'about'}><span className='onDiv'>About</span></div>
                            <div className="navDiv" onClick={() => window.location.href = 'services'}>Services</div>
                            <div className="navDiv" onClick={() => window.location.href = 'services'}>Contact Us</div>
                            <div className="navDiv" onClick={() => window.location.href = 'learning'}>Learning</div>
                            <div className="navDiv" onClick={() => window.location.href = 'socials'}>Community</div>
                            </div>
                    </div>
                </div>
                {_navbarOpen < 1 ?
                    (<div class="miniBarMain">
                        <img className='logoPic' onClick={() => window.location.href = '/'} src={logo} />

                        <div class="navbarMain"><img class="navbar" onClick={navbarOpen} src={navbar} /></div>
                    </div>) :
                    (<div class="miniBarMain">
                        <img className='logoPic' onClick={() => window.location.href = '/'} src={logo} />

                        <div class="navbarMain"><img class="navbar" onClick={closeNav} src={wrong} /></div>
                    </div>)}

                {_navbarOpen > 0 ?
                    (<div class="littleNav">
                        <div className="navDiv" onClick={() => window.location.href = 'about'}><span className='onDiv'>About</span></div>
                        <div className="navDiv" onClick={() => window.location.href = 'services'}>Services</div>
                        <div className="navDiv" onClick={() => window.location.href = 'services'}>Contact Us</div>
                        <div className="navDiv" onClick={() => window.location.href = 'learning'}>Learning</div>
                        <div className="navDiv" onClick={() => window.location.href = 'socials'}>Community</div>
                    </div>) : null}
                <div className='aboutSection'>
                    <div className='eachSections1' data-aos="zoom-in" data-aos-duration="1000">
                        <div className='eachSections-Sub'>
                            <div className='title'>About Us</div>
                            <p>Welcome to [Company Name]! We're a [brief description of your company's industry or niche] dedicated to [company mission]. Since [year of establishment], we've been [brief overview of what the company does or its main offerings].</p>
                        </div>
                        <div className='sec1Img'><img src={section1Img} alt="About Us" /></div>
                    </div>

                    <div className='eachSections2' data-aos="zoom-in" data-aos-duration="1000">
                        <div className='sec2Img'><img src={section1Img} alt="Mission" /></div>
                        <div className='eachSections-Sub'>
                            <div className='title' id="t2">Mission</div>
                            <p id="t2">At [Company Name], our mission is clear: [briefly state the purpose or goal of your company]. We strive to [describe the impact or change your company aims to achieve] through [describe how your company accomplishes its mission].</p>
                        </div>
                    </div>

                    <div className='eachSections3' data-aos="zoom-in" data-aos-duration="1000">
                        <div className='eachSections-Sub'>
                            <div className='title'>Values</div>
                            <p>Our values are the foundation of everything we do at [Company Name]. We believe in [list key values that guide your company's actions, such as integrity, transparency, collaboration, etc.]. These values shape our decisions, interactions, and the way we conduct business every day.</p>
                        </div>
                        <div className='sec3Img'><img src={section1Img} alt="Values" /></div>
                    </div>
                </div>
            </div>

            <div className='whatsappDiv'><img className='whatsapp' src={whatsapp} alt="WhatsApp" /></div>
        </div>
    );
};

export default About;
